import clsx from 'clsx'
import {
  CSSProperties,
  FC,
  memo, ReactNode, useCallback, useEffect, useRef,
  useState } from 'react'

import { AnimationLibrariesProvider, useAnimationLibs } from '@/shared/lib'

import { ButtonProps } from '../Button'
import { Icon } from '../Icon'
import { HStack } from '../Stack'
import { ActionButtons } from './ActionButtons/ActionButtons'
import s from './AppDrawer.module.scss'
import { useAutoHeight } from './useAutoHeight/useAutoHeight'
import { useDrawerDrag } from './useDrawerDrag/useDrawerDrag'

interface DrawerProps {
  className?: string;
  children: ReactNode;
  isOpen?: boolean;
  openHeight?: number;
  closedHeight?: number;
  onClose?: () => void;
  onOpen?: () => void;
  lazy?: boolean;
  removeFromDomOnClose?: boolean;
  autoHeight?: boolean;
  btns: ButtonProps[];
  title?: string;
}

const windowHeight = window.innerHeight

const DrawerContent: FC<DrawerProps> = memo((
  {
    autoHeight,
    className,
    children,
    onOpen,
    onClose,
    isOpen,
    openHeight: propHeight = windowHeight - 12,
    closedHeight = 0,
    btns,
    removeFromDomOnClose = true,
    title,
  },
) => {

  const observerRef = useRef<ResizeObserver | null>(null)
  const contentWrapperRef = useRef<HTMLDivElement | null>(null)
  const [ isClosed, setIsClosed ] = useState(!isOpen)

  const observedHeight = useAutoHeight({ ref: contentWrapperRef, isClosed, autoHeight, observerRef })

  const openHeight = autoHeight ? observedHeight : propHeight

  const { spring } = useAnimationLibs()
  const [ { y }, api ] = spring.useSpring(() => ({ y: openHeight - closedHeight }))

  const openDrawer = useCallback(() => {
    if (contentWrapperRef.current) observerRef.current?.observe(contentWrapperRef.current)

    api.start({ y: 0, immediate: false, onResolve: onOpen })
  }, [ api ])

  const close = (velocity = 0) => {
    // При закрытии выключаем отслеживание
    if (contentWrapperRef.current) observerRef.current?.unobserve(contentWrapperRef.current)

    api.start({
      y: openHeight + 48,
      immediate: false,
      config: { ...spring.config.stiff, velocity },
      onResolve: () => {
        onClose?.()
        removeFromDomOnClose && setIsClosed(true)
      },
    })
  }

  useEffect(() => {
    isOpen && setIsClosed(false)
    isOpen ? openDrawer() : close()
  }, [ isOpen, openDrawer ])

  const bind = useDrawerDrag({ close, openDrawer, y, api, openHeight })

  if (isClosed) return null

  return (
    <div
      className={s.drawer}
      style={{ '--drawer-height': `${openHeight}px` } as CSSProperties}
    >
      <spring.a.div
        className={clsx(s.sheet, className)}
        style={{ y, height: openHeight }}
        {...bind()}
      >
        <div className={s.sheetContent}>
          <div className={s.holdWrapper}>
            <div className={s.hold} />
          </div>
          <div className={s.contentWrapper}>
            <div
              ref={contentWrapperRef}
              className={s.content}
              style={{ height: autoHeight ? undefined : '80%' }}
            >
              {
                title && (
                  <HStack max justify='between'>
                    <h5 className={s.title}>{title}</h5>
                    <button className={s.close} onClick={() => close(0.5)}>
                      <Icon name='close' size={[20, 20]} />
                    </button>
                  </HStack>
                )
              }
              {children}
              <ActionButtons btns={btns} />
            </div>
          </div>
        </div>
      </spring.a.div>
    </div>
  )
})

const DrawerAsync: FC<DrawerProps> = (props) => {
  const { librariesLoaded } = useAnimationLibs()

  if (!librariesLoaded) return null

  return <DrawerContent {...props} />
}

export const AppDrawer: FC<DrawerProps> = (props) => (
  <AnimationLibrariesProvider>
    <DrawerAsync {...props} />
  </AnimationLibrariesProvider>
)
