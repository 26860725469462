import clsx from 'clsx'
import { forwardRef, InputHTMLAttributes, useRef } from 'react'
import { v4 as uuid } from 'uuid'

import s from './Switch.module.scss'

interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  text?: string;
  justify?: 'gap' | 'between';
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(({
  text,
  justify = 'between',
  className,
  id: propId,
  ...rest
}, ref) => {
  const id = useRef(propId ?? uuid())

  return (
    <>
      <input
        ref={ref}
        id={id.current}
        type='checkbox'
        className={s.hiddenInput}
        {...rest}
      />
      <label
        className={clsx(
          s.container,
          s[justify],
          className,
        )}
      >
        {text}
        <label
          className={s.custom}
          htmlFor={id.current}
        >
          <div className={s.circle} />
        </label>
      </label>
    </>
  )
})
