/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** обновление пикчи */
export interface TaskLevelUpdateImageRequest {
  /**
   * id уровня квеста
   * @format int64
   */
  id_lvl: number;
  /** base64-encoded image */
  image: string;
}

/** Уровень задания */
export interface TaskLevelUpdateRequest {
  /**
   * id уровня квеста
   * @format int64
   */
  id_lvl: number;
  /**
   * номер от 1
   * @format int64
   */
  number: number;
  /** Название */
  ru_name: string;
  /** Описание */
  ru_description: string;
  /** Название */
  en_name: string;
  en_description: string;
  /**
   * Количественное условие для выполнения уровня
   * @format int64
   */
  quantitative_condition?: number;
  /**
   * Ограничение на кол-во выполнений уровня квеста
   * @format int64
   */
  execution_limit: number;
  /** Тип награды */
  reward_kind?: RewardKind;
  /** Величина награды */
  reward_value?: string;
}

export interface TaskLevelUpdateResponse {
  /** Квест со всеми уровнями */
  task: FullTask;
}

export interface FullTaskListRequest {
  /** усли true то будут возвращены все квесты вместе с удаленными */
  is_deleted: boolean;
}

export interface FullTaskListResponse {
  tasks: FullTask[];
}

export interface TaskUpdateRequest {
  /**
   * id квеста
   * @format int64
   */
  id: number;
  /** number_of_referrals not require verification check */
  kind: TaskKind;
  /** URL задания */
  ru_task_url?: string;
  /** id канала, если нужна проверка */
  ru_chan_id?: string;
  /** URL задания */
  en_task_url?: string;
  /** id канала, если нужна проверка */
  en_chan_id?: string;
}

export interface TaskUpdateResponse {
  /** Квест со всеми уровнями */
  task?: FullTask;
}

export interface TaskDeleteRequest {
  /** @format int64 */
  id: number;
  is_delete: boolean;
}

export interface TaskDeleteResponse {
  /** Квест со всеми уровнями */
  task: FullTask;
}

export interface TaskCreateRequest {
  /** number_of_referrals not require verification check */
  kind: TaskKind;
  /** URL задания */
  ru_task_url?: string;
  /** id канала, если нужна проверка */
  ru_chan_id?: string;
  /** URL задания */
  en_task_url?: string;
  /** id канала, если нужна проверка */
  en_chan_id?: string;
  /** рекомендуется при создании указывать true, а потом менять */
  is_deleted: boolean;
  /** уровни */
  levels: {
    /**
     * номер от 1
     * @format int64
     */
    number?: number;
    /** base64-encoded image */
    image?: string;
    /** Название */
    ru_name?: string;
    /** Описание */
    ru_description?: string;
    /** Название */
    en_name?: string;
    en_description?: string;
    /**
     * Опционально. Инструкция для квеста в тг. Не юзается в приле
     * @minLength 1
     */
    ru_instractions?: string;
    /**
     * Тоже самое что и ru_instractions, но для англ
     * @minLength 1
     */
    en_instractions?: string;
    /** Список файлов для инструкции */
    ru_instraction_files?: string[];
    /** Список файлов для инструкции */
    en_instraction_files?: string[];
    /** Тип награды */
    reward_kind?: RewardKind;
    /** Величина награды */
    reward_value?: string;
    /**
     * Количественное условие для выполнения уровня
     * @format int64
     */
    quantitative_condition?: number;
    /**
     * Ограничение на кол-во выполнений уровня квеста
     * @format int64
     */
    execution_limit?: number;
  }[];
}

export interface TaskCreateResponse {
  /** Квест со всеми уровнями */
  task?: FullTask;
}

export interface TaskClaimResponse {
  /** Task */
  task?: Task;
  /** player stats */
  player_stats?: PlayerStats;
}

export interface TaskExecutionCheckResponse {
  /** Task */
  task?: Task;
}

export interface TaskListResponse {
  tasks?: Task[];
  /**
   * кол-во активных квестов
   * @format int64
   */
  active_counter?: number;
}

/** Квест со всеми уровнями */
export interface FullTask {
  /** @format int64 */
  id?: number;
  /** number_of_referrals not require verification check */
  kind?: TaskKind;
  /** URL задания */
  ru_task_url?: string;
  /** URL задания */
  en_task_url?: string;
  /** id канала, если нужна проверка */
  ru_chan_id?: string;
  /** id канала, если нужна проверка */
  en_chan_id?: string;
  is_deleted?: boolean;
  levels?: FullTaskLevel[];
}

/** Task */
export interface Task {
  /** @format int64 */
  id?: number;
  /** number_of_referrals not require verification check */
  kind?: TaskKind;
  /** Task url */
  task_url?: string;
  /** First not completed or not claimed level */
  current_level?: TaskLevel;
  /**
   * Number of levels in quest
   * @format int64
   */
  number_levels?: number;
}

/** number_of_referrals not require verification check */
export enum TaskKind {
  WithoutSubscribe = "without_subscribe",
  SubscribeToTelegramChannel = "subscribe_to_telegram_channel",
  NumberOfReferrals = "number_of_referrals",
  ManualVerification = "manual_verification",
  WalletConnection = "wallet_connection",
  ExternalApi = "external_api",
  UploadImages = "upload_images",
}

/** Уровень задания полный */
export interface FullTaskLevel {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  number?: number;
  /** URL на картинку */
  image_url?: string;
  /** Название */
  ru_name?: string;
  /** Описание */
  ru_description?: string;
  /** Название */
  en_name?: string;
  /** Описание */
  en_description?: string;
  /** Тип награды */
  reward_kind?: RewardKind;
  /** Величина награды */
  reward_value?: string;
  /**
   * Количественное условие для выполнения уровня
   * @format int64
   */
  quantitative_condition?: number;
  /**
   * кол-во выполнивших уровень
   * @format int64
   */
  number_done?: number;
  /**
   * Ограничение на кол-во выполнений уровня квеста
   * @format int64
   */
  execution_limit?: number;
  /** Опционально. Инструкция для квеста в тг. Не юзается в приле */
  ru_instractions?: string;
  /** Тоже самое что и ru_instractions, но для англ */
  en_instractions?: string;
  /** Список файлов для инструкции */
  ru_instraction_files?: string[];
  /** Список файлов для инструкции */
  en_instraction_files?: string[];
}

/** Task level */
export interface TaskLevel {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  number?: number;
  /** URL на картинку */
  image_url?: string;
  /** Название */
  name?: string;
  /** Описание */
  description?: string;
  /**
   * Количественное условие для выполнения уровня
   * @format int64
   */
  quantitative_condition?: number;
  /** Тип награды */
  reward_kind?: RewardKind;
  /** Величина награды */
  reward_value?: string;
  /** выполнение уровня */
  done?: boolean;
  /** Клейм награды */
  award_received?: boolean;
}

/** Тип награды */
export enum RewardKind {
  Money = "money",
}

/** Ответ с рефералами */
export interface UserReferralsResponse {
  /** @format int64 */
  total: number;
  /** Lvl 1 referrals of the user */
  referrals?: Referral[];
}

export interface Referral {
  /** Имя из тг */
  name: string;
  /** @format int64 */
  referral_count: number;
}

export interface User {
  tg_username: string;
  name: string;
  language_code: string;
  /** @format int64 */
  lvl1_referral_count: number;
  /** @format int64 */
  lvl2_referral_count: number;
  /**
   * ref system lvl
   * @format int64
   */
  referral_level: number;
  /** ref system lvl effect */
  referral_lvl_effect: string;
  /** ref system next lvl effect */
  referral_next_lvl_effect: string;
  /** total reward money (for each lvl2 referral) */
  total_reward_money: string;
  /**
   * how much referrals left til next lvl
   * @format int64
   */
  referrals_left: number;
  /**
   * referrals lvl progress
   * @format float64
   */
  referrals_lvl_progress: number;
  referral_url: string;
  /**
   * Количество доступных квестов
   * @format int64
   */
  available_tasks_count: number;
}

/** Click */
export interface ClickRequest {
  /**
   * Количество кликов начиная со {start} до отправки запроса
   * @format int64
   */
  amount: number;
  /**
   * unix milli UTC timestamp начала отсчета кликов
   * @format int64
   */
  start: number;
}

/** Покупка upgrade'а */
export interface BuyUpgradeRequest {
  /**
   * upgrade id
   * @format int64
   */
  id: number;
}

/** Игровая инфа об игроке */
export interface PlayerInfo {
  /** player stats */
  stats?: PlayerStats;
  upgrades?: Upgrade[];
  /** URL карты */
  map_url?: string;
}

/** player stats */
export interface PlayerStats {
  /** кол-во деняк. big int */
  balance?: string;
  /** кол-во деняк в секунду. big float */
  passive_rate?: string;
  /** кол-во деняк за клик. big float */
  click_power?: string;
  /**
   * кол-во энергии
   * @format int64
   */
  energy: number;
  /**
   * кол-во энергии
   * @format int64
   */
  max_energy: number;
  /**
   * скорость восстановления энергии в сек
   * @format float64
   */
  energy_update_rate: number;
  /** вместимость хранилища */
  storage_capacity?: string;
  /** текущее кол-во деняк в хранилище */
  storage_amount?: string;
  /**
   * время до заполнения хранилища в миллисекундах
   * @format int64
   */
  time_to_fill: number;
}

/** upgrade */
export interface Upgrade {
  /** @format int64 */
  id: number;
  name: string;
  /** @format int64 */
  level: number;
  /** Тип апгрейда */
  kind?: "Click" | "Passive" | "Storage";
  /** Является ли эффект мультипликативным */
  is_multiplicative: boolean;
  /** Цена след уровня этого upgrade'а */
  next_level_cost?: string;
  /** Можно ли этот апгрейд купить */
  can_be_bought: boolean;
  /** Эффект этого upgrade'а */
  effect?: string;
  /** Эффект этого upgrade'а на след уровне */
  next_level_effect?: string;
  /** URL картинки */
  image_url?: string;
}

/** Типовая ошибка */
export interface ErrorResponse {
  /** Код ошибки */
  code: number;
  /** Сообщение ошибки */
  message?: string;
  /** Дополнительные детали */
  detail?: string;
}

/** Page */
export interface Page {
  /**
   * @format int64
   * @min 1
   */
  page: number;
  /**
   * @format int64
   * @min 1
   */
  size: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://clicker-dev.filemarket.xyz/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Clicker
 * @version 1.0.0
 * @baseUrl https://clicker-dev.filemarket.xyz/api
 *
 * clicker
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  player = {
    /**
     * @description Игровая информация о player'е
     *
     * @tags Game
     * @name InfoList
     * @request GET:/player/info
     * @secure
     */
    infoList: (params: RequestParams = {}) =>
      this.request<PlayerInfo, ErrorResponse>({
        path: `/player/info`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  click = {
    /**
     * @description Игровая механика Click
     *
     * @tags Game
     * @name ClickCreate
     * @request POST:/click
     * @secure
     */
    clickCreate: (req: ClickRequest, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/click`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  claim = {
    /**
     * @description Claim storage
     *
     * @tags Game
     * @name ClaimCreate
     * @request POST:/claim
     * @secure
     */
    claimCreate: (params: RequestParams = {}) =>
      this.request<PlayerStats, ErrorResponse>({
        path: `/claim`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  upgrades = {
    /**
     * @description Покупка улучшения
     *
     * @tags Game
     * @name PostUpgrades
     * @request POST:/upgrades/buy
     * @secure
     */
    postUpgrades: (req: BuyUpgradeRequest, params: RequestParams = {}) =>
      this.request<PlayerInfo, ErrorResponse>({
        path: `/upgrades/buy`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  login = {
    /**
     * @description Логин пользователя
     *
     * @tags User
     * @name LoginCreate
     * @request POST:/login
     * @secure
     */
    loginCreate: (params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/login`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  user = {
    /**
     * @description Запрос на получение рефералов
     *
     * @tags Referral
     * @name ReferralsCreate
     * @request POST:/user/referrals
     * @secure
     */
    referralsCreate: (req: Page, params: RequestParams = {}) =>
      this.request<UserReferralsResponse, ErrorResponse>({
        path: `/user/referrals`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Запрос на получение информации о пользователе
     *
     * @tags User
     * @name InfoList
     * @request GET:/user/info
     * @secure
     */
    infoList: (params: RequestParams = {}) =>
      this.request<User, ErrorResponse>({
        path: `/user/info`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  task = {
    /**
     * @description Запрос на получение задания
     *
     * @tags Task
     * @name TaskDetail
     * @request GET:/task/{id}
     * @secure
     */
    taskDetail: (id: number, params: RequestParams = {}) =>
      this.request<Task, ErrorResponse>({
        path: `/task/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Task
     * @name ListList
     * @request GET:/task/list
     * @secure
     */
    listList: (params: RequestParams = {}) =>
      this.request<TaskListResponse, ErrorResponse>({
        path: `/task/list`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Запрос проверки выполнения задания
     *
     * @tags Task
     * @name ExecutionCheckCreate
     * @request POST:/task/{id}/execution_check
     * @secure
     */
    executionCheckCreate: (id: number, params: RequestParams = {}) =>
      this.request<TaskExecutionCheckResponse, ErrorResponse>({
        path: `/task/${id}/execution_check`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description Получение награды за выполненное задание
     *
     * @tags Task
     * @name ClaimCreate
     * @request POST:/task/{id}/claim
     * @secure
     */
    claimCreate: (id: number, params: RequestParams = {}) =>
      this.request<TaskClaimResponse, ErrorResponse>({
        path: `/task/${id}/claim`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  admin = {
    /**
     * @description Создание квеста
     *
     * @tags Admin, Task
     * @name TaskCreateCreate
     * @request POST:/admin/task/create
     * @secure
     */
    taskCreateCreate: (req: TaskCreateRequest, params: RequestParams = {}) =>
      this.request<TaskCreateResponse, ErrorResponse>({
        path: `/admin/task/create`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Удаление квеста
     *
     * @tags Admin, Task
     * @name TaskDeleteCreate
     * @request POST:/admin/task/delete
     * @secure
     */
    taskDeleteCreate: (req: TaskDeleteRequest, params: RequestParams = {}) =>
      this.request<TaskDeleteResponse, ErrorResponse>({
        path: `/admin/task/delete`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Апдейт квеста
     *
     * @tags Admin, Task
     * @name TaskUpdateCreate
     * @request POST:/admin/task/update
     * @secure
     */
    taskUpdateCreate: (req: TaskUpdateRequest, params: RequestParams = {}) =>
      this.request<TaskUpdateResponse, ErrorResponse>({
        path: `/admin/task/update`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin, Task
     * @name TaskDetail
     * @request GET:/admin/task/{id}
     * @secure
     */
    taskDetail: (id: number, params: RequestParams = {}) =>
      this.request<FullTask, ErrorResponse>({
        path: `/admin/task/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin, Task
     * @name TaskListCreate
     * @request POST:/admin/task/list
     * @secure
     */
    taskListCreate: (req: FullTaskListRequest, params: RequestParams = {}) =>
      this.request<FullTaskListResponse, ErrorResponse>({
        path: `/admin/task/list`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Апдейт уровня квеста
     *
     * @tags Admin, Task
     * @name TaskLevelUpdateCreate
     * @request POST:/admin/task_level/update
     * @secure
     */
    taskLevelUpdateCreate: (req: TaskLevelUpdateRequest, params: RequestParams = {}) =>
      this.request<TaskLevelUpdateResponse, ErrorResponse>({
        path: `/admin/task_level/update`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Апдейт пикчи уровня квеста
     *
     * @tags Admin, Task
     * @name TaskLevelUpdateImageCreate
     * @request POST:/admin/task_level/update_image
     * @secure
     */
    taskLevelUpdateImageCreate: (req: TaskLevelUpdateImageRequest, params: RequestParams = {}) =>
      this.request<TaskLevelUpdateResponse, ErrorResponse>({
        path: `/admin/task_level/update_image`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
