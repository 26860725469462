import { FC } from 'react'

import { Flex } from '../Flex/Flex'
import { FlexProps } from '../flexTypes'

type HStackProps = Omit<FlexProps, 'direction'>;

export const HStack: FC<HStackProps> = (props) => (
  <Flex {...props} direction="row" />
)
