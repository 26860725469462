import { SpringRef, SpringValue } from '@react-spring/web'

import { useAnimationLibs } from '@/shared/lib'

interface UseDrawerDragProps {
  close: () => void;
  openDrawer: () => void;
  y: SpringValue<number>;
  openHeight: number;
  api: SpringRef<{ y: number }>
}

export const useDrawerDrag = ({ close, openDrawer, y, openHeight, api }:UseDrawerDragProps) => {
  const { gesture } = useAnimationLibs()

  return gesture.useDrag(
    ({
      last,
      velocity: [ , vy ],
      direction: [ , dy ],
      movement: [ , my ],
      offset: [ , oy ],
      down,
    }) => {
      if (oy < -80) return openDrawer()
      if (oy > openHeight - 100) return close()

      if (!last) return api.start({ y: oy, immediate: down && Math.abs(my) > 50 })

      if (my >= openHeight - 32 || vy > 0.5 && dy >= 0) return close()

      my && openDrawer()

      return
    },
    { from: () => [ 0, y.get() ], filterTaps: true, rubberband: true, bounds: { top: 50 } },
  )
}
