import { FC, ReactNode } from 'react'

import s from './Horizontal.module.scss'

interface HorizontalTabProps {
  children: ReactNode;
  index: number;
  value: number;
}

export const HorizontalTab: FC<HorizontalTabProps> = ({ children, index, value }) => {
  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      className={s.tab}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
    >
      {value === index && children}
    </div>
  )
}
