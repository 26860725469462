import { MutableRefObject, useEffect, useState } from 'react'

const windowHeight = window.innerHeight
const HOLD_HEIGHT = 5

interface UseAutoHeightProps {
  ref: MutableRefObject<HTMLDivElement | null>;
  isClosed: boolean;
  autoHeight?: boolean;
  observerRef: MutableRefObject<ResizeObserver | null>
}

export const useAutoHeight = ({ autoHeight, isClosed, ref, observerRef }: UseAutoHeightProps) => {
  const [ observedHeight, setObservedHeight ] = useState<number>(windowHeight - 12)

  // следим за высотой если надо
  useEffect(() => {
    if (!autoHeight || !ref.current || isClosed) return

    observerRef.current = new ResizeObserver(entries => {
      const wrapperEntry = entries[0]

      if (!wrapperEntry) return

      const { contentRect: { height: wrapperHeight } } = wrapperEntry
      // ограничиваем высоту. Не более чем высота экрана.
      const boundedHeight = Math.min(windowHeight, wrapperHeight + HOLD_HEIGHT)
      setObservedHeight(boundedHeight)
    })

    observerRef.current?.observe(ref.current)

    return () => {
      // здесь отключаем обсервер и чистим реф
      observerRef.current?.disconnect()
      observerRef.current = null
    }
  }, [ autoHeight, isClosed ])

  return observedHeight + 48
}
