import { ReactNode, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useTaskCount } from '@/entities/user'
import { Counter } from '@/shared/ui'

import { type MenuItem as MenuItemType, type MenuItemName, menuItems } from '../model/menuItems/menuItems'
import { MenuItem } from '../ui/MenuItem/MenuItem'
import s from './useMenuItems.module.scss'

export const useMenuItems = () => {
  const count = useTaskCount()

  //assigning location variable
  const location = useLocation()

  //destructuring pathname from location
  const { pathname } = location

  const additionalRecord: Record<MenuItemName, ReactNode> = useMemo(() => ({
    Quests: <Counter count={count ?? 0} className={s.counter} />,
    Build: null,
    Labs: null,
    Squad: null,
  }), [count])

  const renderLink = useCallback((menuItem: MenuItemType) => {
    const { imgPath, name, path, disabled } = menuItem

    return (
      <MenuItem
        key={name}
        imgPath={imgPath}
        name={name}
        path={path}
        disabled={disabled}
        pathname={pathname}
        additional={additionalRecord[name]}
      />
    )
  }, [pathname, additionalRecord])

  return menuItems.map(renderLink)
}
