import clsx from 'clsx'
import { forwardRef } from 'react'

import s from './Card.module.scss'
import { CardProps } from './cardTypes'

export const Card = forwardRef<HTMLDivElement, CardProps>((
  {
    variant = 'default',
    className,
    radius = '12',
    children,
    enlarge,
    style,
    ...rest
  }, ref,
) => {

  return (
    <div
      ref={ref}
      style={{ ...style, borderRadius: `${radius}px` }}
      className={clsx(
        s.card,
        s[variant],
        enlarge && s.paddingEnlarge,
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  )
})
