import { Tab, Tabs } from '@mui/material'
import { CSSProperties, FC, memo, ReactNode, useState } from 'react'

import { VStack } from '../../Stack'
import { FlexGap } from '../../Stack/flexTypes'
import { HorizontalTab } from '../Horizontal/HorizontalTab'
import s from './Tabs.module.scss'

interface AppTabsProps {
  tabsItems: ReactNode[];
  tabContentItems: ReactNode[];
  gap?: FlexGap;
  disabledIndex?: number[];
}

function a11yProps(index: number) {
  return {
    id: `horizontal-tab-${index}`,
    'aria-controls': `horizontal-tabpanel-${index}`,
  }
}

export const AppTabs: FC<AppTabsProps> = memo(({ tabContentItems, tabsItems, gap = '12', disabledIndex, ...rest }) => {
  const [value, setValue] = useState(0)

  const tabWidth = (100 / tabsItems.length).toFixed(1)

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const TabContent = (label: ReactNode, showIndicator: boolean) => (
    <VStack max align='center'>
      {label}
      {showIndicator && <span className={s.indicatorSpan} />}
    </VStack>
  )

  return (
    <VStack
      max
      gap={gap}
      align='center'
      style={{ '--tab-width': `${tabWidth}%` } as CSSProperties}
      className={s.container}
    >
      <Tabs
        {...rest}
        centered
        value={value}
        onChange={handleChange}
      >
        {
          tabsItems.map((label, i) => (
            <Tab
              disabled={disabledIndex?.includes(i)}
              label={TabContent(label, value === i)}
              {...a11yProps(i)}
              key={i}
            />
          ))
        }
      </Tabs>
      {
        tabContentItems.map((TabContent, i) => (
          <HorizontalTab key={i} value={value} index={i}>
            {TabContent}
          </HorizontalTab>
        ))
      }
    </VStack>
  )
})
