import { FC } from 'react'

import { AppButton, ButtonProps, VStack } from '@/shared/ui'

interface ActionButtonsProps {
  btns?: ButtonProps[];
}

export const ActionButtons: FC<ActionButtonsProps> = ({ btns }) => {

  return (
    <VStack max gap="12">
      {
        btns?.map(({ children, ...rest }, i) => (
          <AppButton key={i} {...rest}>
            {children}
          </AppButton>
        ))
      }
    </VStack>
  )
}
